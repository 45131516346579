export const colors = {
  white: '#ffffff',
  brown: '#BA6F4B',
  brown100: '#E1D3C7',
  grey100: '#F8F6F3',
  grey300: '#EEEAE2',
  grey400: '#D4D7D9',
  grey600: '#a9acad',
  black: '#181A18',
};
