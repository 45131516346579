import { createGlobalStyle } from 'styled-components';

import Montserrat300 from 'src/static/fonts/montserrat-v26-latin-ext-300.woff2';
import Montserrat400 from 'src/static/fonts/montserrat-v26-latin-ext-regular.woff2';
import Montserrat500 from 'src/static/fonts/montserrat-v26-latin-ext-500.woff2';

const FontStyles = createGlobalStyle`
   @font-face {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 300;
        src: url(${Montserrat300}) format("woff2");
    }

    @font-face {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 400;
        src: url(${Montserrat400}) format("woff2");
    }

    @font-face {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        src: url(${Montserrat500}) format("woff2");
    }
`;

export default FontStyles;
