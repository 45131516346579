exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-apie-tsx": () => import("./../../../src/pages/apie.tsx" /* webpackChunkName: "component---src-pages-apie-tsx" */),
  "component---src-pages-dovanu-kuponas-tsx": () => import("./../../../src/pages/dovanu-kuponas.tsx" /* webpackChunkName: "component---src-pages-dovanu-kuponas-tsx" */),
  "component---src-pages-grazinimo-salygos-tsx": () => import("./../../../src/pages/grazinimo-salygos.tsx" /* webpackChunkName: "component---src-pages-grazinimo-salygos-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kainos-tsx": () => import("./../../../src/pages/kainos.tsx" /* webpackChunkName: "component---src-pages-kainos-tsx" */),
  "component---src-pages-kontaktai-tsx": () => import("./../../../src/pages/kontaktai.tsx" /* webpackChunkName: "component---src-pages-kontaktai-tsx" */),
  "component---src-pages-odos-testas-tsx": () => import("./../../../src/pages/odos-testas.tsx" /* webpackChunkName: "component---src-pages-odos-testas-tsx" */),
  "component---src-pages-paslaugos-tsx": () => import("./../../../src/pages/paslaugos.tsx" /* webpackChunkName: "component---src-pages-paslaugos-tsx" */),
  "component---src-pages-perkamiausi-produktai-tsx": () => import("./../../../src/pages/perkamiausi-produktai.tsx" /* webpackChunkName: "component---src-pages-perkamiausi-produktai-tsx" */),
  "component---src-pages-pirkimo-taisykles-tsx": () => import("./../../../src/pages/pirkimo-taisykles.tsx" /* webpackChunkName: "component---src-pages-pirkimo-taisykles-tsx" */),
  "component---src-pages-prekiu-pristatymas-tsx": () => import("./../../../src/pages/prekiu-pristatymas.tsx" /* webpackChunkName: "component---src-pages-prekiu-pristatymas-tsx" */),
  "component---src-pages-privatumo-politika-tsx": () => import("./../../../src/pages/privatumo-politika.tsx" /* webpackChunkName: "component---src-pages-privatumo-politika-tsx" */),
  "component---src-pages-produktai-su-nuolaida-tsx": () => import("./../../../src/pages/produktai-su-nuolaida.tsx" /* webpackChunkName: "component---src-pages-produktai-su-nuolaida-tsx" */),
  "component---src-pages-produktai-tsx": () => import("./../../../src/pages/produktai.tsx" /* webpackChunkName: "component---src-pages-produktai-tsx" */),
  "component---src-pages-straipsniai-tsx": () => import("./../../../src/pages/straipsniai.tsx" /* webpackChunkName: "component---src-pages-straipsniai-tsx" */),
  "component---src-pages-uzsakymas-index-tsx": () => import("./../../../src/pages/uzsakymas/index.tsx" /* webpackChunkName: "component---src-pages-uzsakymas-index-tsx" */),
  "component---src-pages-uzsakymas-uzsakymas-gautas-tsx": () => import("./../../../src/pages/uzsakymas/uzsakymas-gautas.tsx" /* webpackChunkName: "component---src-pages-uzsakymas-uzsakymas-gautas-tsx" */),
  "component---src-templates-category-tsx": () => import("./../../../src/templates/Category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/Post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-product-category-tsx": () => import("./../../../src/templates/ProductCategory.tsx" /* webpackChunkName: "component---src-templates-product-category-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/Product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */),
  "component---src-templates-service-tsx": () => import("./../../../src/templates/Service.tsx" /* webpackChunkName: "component---src-templates-service-tsx" */)
}

