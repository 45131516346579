import React, { FC, HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';

import { ButtonAppearance } from './constants';
import { colors } from 'src/theme/colors';
import { spinAnimation } from 'src/components/Spinner/Spinner';

export const baseButtonStyles = css`
  align-items: center;
  border: none;
  border-radius: 5px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  font-family: inherit;
  font-size: 16px;
  font-weight: 300;
  justify-content: center;
  letter-spacing: 0.8px;
  min-height: 46px;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition:
    transform 300ms,
    box-shadow 300ms;
  white-space: nowrap;

  &:hover {
    transform: translateY(-2px);
  }

  &:focus:not(:focus-visible) {
    outline: 0;
  }
`;

export const ButtonAppearancesStyles = css<{ $appearance: ButtonAppearance }>`
  ${({ $appearance }) =>
    $appearance === ButtonAppearance.ALFA &&
    css`
      ${baseButtonStyles}
      background-color: ${colors.brown};
      color: ${colors.white};
    `}

  ${({ $appearance }) =>
    $appearance === ButtonAppearance.BRAVO &&
    css`
      ${baseButtonStyles}
      background-color: transparent;
      border: 1px solid ${colors.brown};
      color: ${colors.brown};
    `}
`;

const StyledInternalLink = styled(Link)<{ $appearance: ButtonAppearance }>`
  ${ButtonAppearancesStyles};
`;

const StyledExternalLink = styled.a<{ $appearance: ButtonAppearance }>`
  ${ButtonAppearancesStyles};
`;

const StyledButton = styled.button<{ $appearance: ButtonAppearance }>`
  ${ButtonAppearancesStyles};
`;

const Spinner = styled.div`
  border: 2px solid ${colors.white};
  border-radius: 50%;
  border-top: 2px solid ${colors.brown};
  width: 12px;
  height: 12px;
  animation: ${spinAnimation} 1s linear infinite;
  margin-right: 8px;
`;

interface ButtonProps
  extends HTMLAttributes<
    HTMLButtonElement | HTMLAnchorElement | HTMLDivElement
  > {
  appearance: ButtonAppearance;
  children: React.ReactNode | string;
  url?: string;
  isButton?: boolean;
  isExternal?: boolean;
  className?: string;
  isLoading?: boolean;
  onClick?: () => void;
}

const Button: FC<ButtonProps> = ({
  appearance,
  url,
  children,
  isButton,
  isExternal,
  className,
  isLoading,
  onClick,
}) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <>
      {isExternal && !isButton && url && (
        <StyledExternalLink
          className={className}
          $appearance={appearance}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleClick}
        >
          {isLoading && <Spinner />}
          {children}
        </StyledExternalLink>
      )}

      {!isExternal && !isButton && url && (
        <StyledInternalLink
          to={url}
          $appearance={appearance}
          className={className}
          onClick={handleClick}
        >
          {isLoading && <Spinner />}
          {children}
        </StyledInternalLink>
      )}

      {isButton && !url && (
        <StyledButton
          $appearance={appearance}
          className={className}
          onClick={handleClick}
          disabled={isLoading}
        >
          {isLoading && <Spinner />}
          {children}
        </StyledButton>
      )}
    </>
  );
};

export default Button;
