import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
  useEffect,
} from 'react';

type AppState = {
  cart?: Cart;
  setCart: Dispatch<SetStateAction<Cart | undefined>>;
};

export type CartProduct = {
  name?: string;
  databaseId: number;
  sku: string;
  price: string;
  uri: string;
  image?: { sourceUrl: string };
};

export type ShippingPackage = {
  rates: {
    cost: string;
    id: number;
    label: string;
    methodId: number;
  };
};

export type Cart = {
  subtotal?: string;
  total?: string;
  shippingTotal?: string;
  appliedCoupons?: Array<{ code: string; discountAmount: string }>;
  availableShippingMethods?: ShippingPackage;
  chosenShippingMethods: string[];
  contents: {
    itemCount: number;
    nodes: Array<{
      key: string;
      quantity: number;
      subtotal: string;
      product: { node: CartProduct };
    }>;
  };
};

const defaultState: AppState = {
  setCart: () => {
    // Set Cart
  },
  cart: undefined,
};

const AppContext = createContext(defaultState);

interface AppProps {
  children: React.ReactNode;
}

const AppProvider: React.FC<AppProps> = ({ children }) => {
  const [cart, setCartState] = useState<AppState['cart']>(() => {
    if (typeof window !== 'undefined') {
      try {
        const storedCart = localStorage.getItem('cart');
        return storedCart ? JSON.parse(storedCart) : defaultState.cart;
      } catch (error) {
        console.error('Error parsing cart from localStorage:', error);
        return defaultState.cart;
      }
    } else {
      return defaultState.cart;
    }
  });

  const setCart: AppState['setCart'] = newCart => {
    setCartState(newCart);
    if (typeof window !== 'undefined') {
      try {
        localStorage.setItem('cart', JSON.stringify(newCart));
      } catch (error) {
        console.error('Error saving cart to localStorage:', error);
      }
    }
  };

  useEffect(() => {
    if (cart !== undefined) {
      if (typeof window !== 'undefined') {
        try {
          localStorage.setItem('cart', JSON.stringify(cart));
        } catch (error) {
          console.error('Error saving cart to localStorage:', error);
        }
      }
    }
  }, [cart]);

  return (
    <AppContext.Provider value={{ cart, setCart }}>
      {children}
    </AppContext.Provider>
  );
};

const useAppState = () => useContext(AppContext);

export { AppProvider, useAppState };
