import React from 'react';
import { ApolloProvider } from '@apollo/client';
import ReactDOM from 'react-dom/client';
import { register } from 'swiper/element/bundle';
import 'sanitize.css';

import { client } from './src/components/apolloClient';
import { AppProvider } from './src/components/context';
import { CartProvider } from './src/components/Cart/CartContext';
import GlobalStyles from './src/static/styles/global.css';
import FontStyles from './src/theme/fonts';

export const onClientEntry = () => {
  register(); // Register swiper.js
};

export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>
    <AppProvider>
      <CartProvider>{element}</CartProvider>
    </AppProvider>
  </ApolloProvider>
);

export const wrapPageElement = ({ element }) => (
  <>
    <GlobalStyles />
    <FontStyles />
    {element}
  </>
);

export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container);
    root.render(element);
  };
};
