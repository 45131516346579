import { createGlobalStyle } from 'styled-components';

import theme from 'src/theme/theme';
import { colors } from 'src/theme/colors';
import { device } from 'src/theme/breakpoints';
import { linkStyles } from 'src/components/Link/Link';
import { baseInputStyles } from 'src/components/ContactForm/ContactForm';

export default createGlobalStyle`  
  html {
    scroll-padding-top: 94px;

    @media ${device.tabletUP} {
      scroll-padding-top: 124px;
    }
  }

  body {
    background-color: ${colors.grey100};
    color: ${colors.black};
    font-family: ${theme.primaryFont};
    font-weight: 300;
    font-size: 16px;
    letter-spacing: 0.6px;
    line-height: 1.7;
  }

  a {
    text-decoration: none;
  }

  p > a, li > a {
    ${linkStyles};
  }

  .overflow-hidden {
    overflow: hidden;

    &:after {
      background-color: rgba(0, 0, 0, 0.6);
      content: "";
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 1;
    }
  }

  p, li, h1, h2, h3 {
    user-select: none;
  }

  .highlight {
    color: ${colors.brown};
  }

  .StripeElement {
    ${baseInputStyles};
    background-color: ${colors.white};
    display: grid;
    align-items: center;
    height: 46px;

    @media ${device.mobileDOWN} {
      height: 40px;
    }
  }

  button {
    font-family: ${theme.primaryFont};
  }
`;
