import { css } from 'styled-components';

export const LinkAnimation = css`
  display: inline;
  position: relative;

  &:after {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: '';
    display: block;
    height: 1px;
    left: 0;
    position: absolute;
    transition: width 0.5s ease 0s, left 0.5s ease 0s;
    width: 0;
  }

  &:hover:after {
    left: 0;
    width: 100%;
  }
`;
