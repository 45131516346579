// Link.tsx
import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';

import { colors } from 'src/theme/colors';
import { LinkAnimation } from 'src/utils/animations';

export const linkStyles = css`
  ${LinkAnimation};
  color: ${colors.brown};

  &[href^='tel:'] {
    white-space: nowrap;
  }

  &:after {
    background-color: ${colors.brown};
  }
`;

const InternalLink = styled(GatsbyLink)`
  ${linkStyles};
`;

const ExternalLink = styled.a`
  ${linkStyles};
`;

interface LinkProps {
  url: string;
  children: React.ReactNode;
  className?: string;
  onClick?: () => void; // Add onClick prop
}

const Link: FC<LinkProps> = ({ url, children, className, onClick }) => {
  const internal = /^\/(?!\/)/.test(url);

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  if (internal) {
    return (
      <InternalLink to={url} className={className} onClick={handleClick}>
        {children}
      </InternalLink>
    );
  }

  return (
    <ExternalLink
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className={className}
      onClick={handleClick}
    >
      {children}
    </ExternalLink>
  );
};

export default Link;
