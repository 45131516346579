const size = {
  mobile: 375,
  tablet: 768,
  laptop: 1024,
  laptopL: 1400,
  laptopXL: 1920,
};

export const device = {
  mobileS: `(max-width: ${size.mobile - 1}px)`,
  mobileDOWN: `(max-width: ${size.tablet - 1}px)`,
  mobile: `(min-width: ${size.mobile}px) and (max-width: ${size.tablet - 1}px)`,
  mobileUP: `(min-width: ${size.mobile}px)`,
  tabletDOWN: `(max-width: ${size.laptop - 1}px)`,
  tablet: `(min-width: ${size.tablet}px) and (max-width: ${size.laptop - 1}px)`,
  tabletUP: `(min-width: ${size.tablet}px)`,
  laptopDOWN: `(max-width: ${size.laptopL - 1}px)`,
  laptop: `(min-width: ${size.laptop}px) and (max-width: ${
    size.laptopL - 1
  }px)`,
  laptopUP: `(min-width: ${size.laptop}px)`,
  laptopLDOWN: `(max-width: ${size.laptopXL - 1}px)`,
  laptopL: `(min-width: ${size.laptopL}px) and (max-width: ${
    size.laptopXL - 1
  }px)`,
  laptopLUP: `(min-width: ${size.laptopL}px)`,
  laptopXL: `(min-width: ${size.laptopXL}px)`,
};
