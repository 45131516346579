import React, { FC, useState } from 'react';
import styled, { css } from 'styled-components';

import { device } from 'src/theme/breakpoints';
import theme from 'src/theme/theme';
import { colors } from 'src/theme/colors';
import { baseButtonStyles } from '../Button/Button';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  width: 100%;

  @media ${device.tabletDOWN} {
    margin-top: 30px;
  }
`;

export const FormRow = styled.div`
  column-gap: 16px;
  display: flex;
  row-gap: 12px;

  @media ${device.mobileDOWN} {
    flex-direction: column;
    margin-bottom: 8px;
  }
`;

export const baseInputStyles = css`
  border: 1px solid ${colors.grey400};
  border-radius: 5px;
  flex: 1 1 auto;
  font-family: ${theme.primaryFont};
  font-size: 16px;
  height: 48px;
  margin-top: 8px;
  padding: 0 8px;

  &::placeholder {
    font-family: ${theme.primaryFont};
  }

  &:focus-visible {
    outline: 1px solid ${colors.brown};
  }
`;

const Input = styled.input`
  ${baseInputStyles}
  height: 48px;
`;

const Label = styled.label`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${device.mobileDOWN} {
    font-size: 14px;
    margin-top: 8px;
  }
`;

const TextArea = styled.textarea`
  ${baseInputStyles}
  min-height: 124px;
  padding: 8px;
`;

const StyledButton = styled.button`
  ${baseButtonStyles}
  align-self: flex-start;
  background-color: transparent;
  border: 1px solid ${colors.brown};
  box-shadow: none;
  color: ${colors.brown};
  margin-top: 16px;

  @media ${device.mobileDOWN} {
    margin-top: 8px;
  }
`;

const ContactForm: FC = () => {
  const [formMessage, setFormMessage] = useState<string>('');

  const handleSubmit = event => {
    event.preventDefault();
    const formData = new FormData(event.target);

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(formData as any).toString(),
    })
      .then(() =>
        setFormMessage(
          'Ačiū. Jūsų užklausa sėkmingai išsiųsta su jumis susisieksime artimiausiu metu.'
        )
      )
      .catch(() =>
        setFormMessage(
          'Jūsų užklausos išsiųsti nepavyko. Patikrinkite laukelius ir bandykite dar kartą.'
        )
      );
  };

  return (
    <Form name="contact" data-netlify="true" onSubmit={handleSubmit}>
      <input type="hidden" name="form-name" value="contact" />
      <FormRow>
        <Label>
          Vardas
          <Input type="text" name="name" />
        </Label>
        <Label>
          El. paštas*
          <Input type="email" name="email" required />
        </Label>
      </FormRow>
      <FormRow>
        <Label>
          Žinutė
          <TextArea name="message" />
        </Label>
      </FormRow>
      <StyledButton>Siųsti</StyledButton>
      {formMessage && <p>{formMessage}</p>}
    </Form>
  );
};

export default ContactForm;
