import React, { FC } from 'react';
import styled, { keyframes } from 'styled-components';

import { colors } from 'src/theme/colors';

export const spinAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const StyledSpinner = styled.div`
  animation: ${spinAnimation} 1s linear infinite;
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid ${colors.brown};
  height: 30px;
  width: 30px;
`;

const Spinner: FC = () => <StyledSpinner />;

export default Spinner;
